import React, { FormEventHandler } from "react";
import { Button } from "../FormComponents/Buttons/Button";
import LoadingSpinner from "../LoadingSpinner/LoadingSpinner";
import Select, { ActionMeta, SingleValue } from 'react-select';
import './LanguagePicker.scss';
import { LanguageSelectOption } from "../../hooks/useLanguagePreference";

interface LanguagePickerProps {
  options: LanguageSelectOption[] | null;
  onSubmit: FormEventHandler<HTMLFormElement>;
  onChange: (newValue: SingleValue<LanguageSelectOption>, actionMeta: ActionMeta<LanguageSelectOption>) => void;
  placeholder: string;
}

const LanguagePicker = ({ options, onSubmit, onChange, placeholder }: LanguagePickerProps) => {
  return (
    <form onSubmit={onSubmit} className="language-form">
      {options ? (
        <>
          <Select
            options={options}
            autoFocus
            placeholder={placeholder}
            onChange={onChange}
          />
          <Button
            type="submit"
            variant="outlined"
            colour="black"
            translationKey="language.select"
          />
        </>
      ) : (
        <LoadingSpinner isLocal={true} />
      )}
    </form>
  )
}

export default LanguagePicker;