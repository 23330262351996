import * as React from 'react';
import { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import './Landing.scss';
import {
  LanguageSelectOption,
  REQUESTED_URI_KEY,
  useLanguagePreference,
} from '../../hooks/useLanguagePreference';
import { isGlobalSite } from '../../helpers/countrySubdomainHelper';
import LanguagePicker from '../../components/LanguagePicker/LanguagePicker';

export const Landing = () => {
  const [chosenLanguage, setChosenLanguage] = useState<LanguageSelectOption | null>(null);
  const {
    context: { languageOptions, userLanguagePreference },
    changeLanguageHandler
  } = useLanguagePreference();

  const navigate = useNavigate();
  const { t } = useTranslation();

  const navigateToDeepLinkIfSet = useCallback(() => {
    const deepLinkUri = localStorage.getItem(REQUESTED_URI_KEY);
    if (deepLinkUri != null) {
      localStorage.removeItem(REQUESTED_URI_KEY);
      navigate(deepLinkUri);
    } else {
      navigate('/home');
    }
  }, [navigate]);

  useEffect(() => {
    if (userLanguagePreference && !isGlobalSite) {
      navigateToDeepLinkIfSet();
    }
  }, [userLanguagePreference, navigateToDeepLinkIfSet]);

  const handleSelectChange = (option: LanguageSelectOption | null) => {
    if (!option) {
      return;
    }
    setChosenLanguage(option);
  };

  const handleSubmit = async (evt: any) => {
    evt.preventDefault();
    chosenLanguage && await changeLanguageHandler(chosenLanguage, false);
  };

  return (
    <div className="login-container">
      <div className="col-md-6 align-self-start info">{t('language.intro')}</div>
      <div id="login-box" className="col-md-4 login-box">
        <div className="login-header">
          <div style={{ textAlign: 'center' }}>
            <img
              src="https://genetherapy-web-dev.azurewebsites.net/content/img/logo-login.svg"
              alt="Novartis Flexterity Logo"
            />
          </div>
        </div>
        <LanguagePicker
          options={languageOptions} 
          onSubmit={handleSubmit} 
          onChange={handleSelectChange} 
          placeholder={isGlobalSite ? t('country.placeholder') : t('language.placeholder')} 
        />
      </div>
    </div>
  );
};
