import { useGet } from 'restful-react';
import { getCountryOriginUrl, isGlobalSite } from '../helpers/countrySubdomainHelper';
import { useAuth0 } from '@auth0/auth0-react';
import { useEffect, useState } from 'react';

export const useCountrySiteRouting = () => {
  const { isAuthenticated, logout } = useAuth0();
  const [loading, setLoading] = useState(true);

  const { data: userDbLocale, refetch } = useGet({
    path: '/user/locale',
    lazy: true
  });

  useEffect(() => {
    // Handle redirect to user's persisted locale inferred country site
    if (isAuthenticated) {
      if (!userDbLocale) refetch();
    }
    else {
      setLoading(false);
    }
    if (userDbLocale) {
      if (userDbLocale?.inferredCountry && !isGlobalSite) {
        const userCountryOrigin = getCountryOriginUrl(userDbLocale);
        if (userCountryOrigin !== window.location.origin) {
          logout({ returnTo: userCountryOrigin });
        }
        else {
          setLoading(false);
        }
      }
    }
  }, [isAuthenticated, userDbLocale, refetch, logout]);

  return loading;
};